import { reset } from './reset';
import { grid } from './grid';
import { createGlobalStyle } from 'styled-components';
import { mediaQuery } from './mixins';

export default createGlobalStyle`

  @font-face {
    font-family: 'Poppins', sans-serif;
    src: url("../Fonts/Poppins-Regular.ttf");
    src: url("../Fonts/Poppins-Medium.ttf");
    src: url("../Fonts/Poppins-SemiBold.ttf");
    src: url("../Fonts/Poppins-Bold.ttf");
  }
  
  ${reset}
  ${grid}
  
  p {
    letter-spacing: .48px;

    ${mediaQuery.desktop`
      letter-spacing:  inherit;
    `}
  }


`;
