import React from "react";
import styled from 'styled-components';
import { navigate } from "gatsby";

import Button from "../components/Button";

import GlobalStyle from "../assets/styles/global";
import { mediaQuery } from "../assets/styles/mixins";
import { useWindowSize } from "../hooks/resizeWindow";

const Home: React.FC = () => {
    const size = useWindowSize();

    const notFound = 'https://zipdin-images.s3.amazonaws.com/not-found.svg';
    const notFoundMobile = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/not-found-mobile.svg';
    const logo = 'https://zipdin-images.s3.amazonaws.com/zipdin-horizontal-cinza.svg';

    const handlePage = React.useCallback(() => {
        navigate("/");
    }, []);
    return (
        <>
            <Container>
                <NotFoundImage
                    src={
                        size.width < 800 ? notFoundMobile : notFound
                    }
                    alt={'not-found'} />

                <Button
                    onClick={handlePage}
                    hiddenIcon={true}
                    isNotFoundPage={true}
                    className="btn-home-credit"
                >
                    VOLTAR PARA O INÍCIO
                    </Button>
                <Footer>
                    <Logo src={logo} alt={'fs'} />
                    <span>Copyright © 2021 - Zipdin Consignado Privado.</span>
                    <span>Todos os Direitos Reservados.</span>
                </Footer>
            </Container>
            <GlobalStyle />
        </>
    );
};

export default Home;

const NotFoundImage = styled.img`
    max-width: 80vw;

    ${mediaQuery.desktop`
        max-width: 66vw;
    `}
`;

const Logo = styled.img`
    height: 35px;
    width: 120px;
    margin-bottom: 2vh;

    ${mediaQuery.desktop`
        max-height: 4vh;
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 4vh 0vw 10vh 0vw;
    background-color: ${props => props.theme.bg.sixth};
    
    ${mediaQuery.desktop`
        padding: 10vh 0vw 4vh 0vw;
    `}
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    span {
        font-size: 12px;
        color: ${props => props.theme.font.fifth};
    }
`;