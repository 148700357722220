import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.button`
  background-color: ${({ theme }) => theme.bg.second};
  color: ${({ theme }) => theme.font.white};
  font-weight: 400;
  border: none;
  border-radius: 5px;
  transition:  all .425s ease-in-out;
  border: 1px ${({ theme }) => theme.border.borderBtn} solid;

  ${props => props.isNotFoundPage ? `
    height: 5.4vh;
    max-width: 61vw;
    padding: 10px 20px;
    font-size: 14px;

    ${mediaQuery.desktop`
        max-width: 13.3vw;
        height: 4.6vh;
      `}

    ${mediaQuery.phone`
        max-width: 65vw;
    `}

    ` : `

      padding: 0.75rem 1.25rem;
      font-size: 16px;
  `};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 5px;

    path {
      transition:  all .3s ease-in;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.bg.primary};
    color: ${({ theme }) => theme.font.third};

    svg {
      path {
        fill: ${({ theme }) => theme.bg.second};
      }
    }
  }
`;