import { css } from 'styled-components';
import { breakpoints } from './_variables';

export const mediaQuery:any = Object.keys(breakpoints).reduce(
  (DeviceRules, label) => {
    DeviceRules[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return DeviceRules;
  },
  {}
);