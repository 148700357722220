import React from 'react';

import { Container } from './styles';

interface ButtonProps {
  type?: string;
  hiddenIcon?: boolean;
  isNotFoundPage?: boolean;
  onClick?: any;
  className?: string;
  children?: any;
}

const Button: React.FC<ButtonProps> = ({ type = 'button', hiddenIcon = false, isNotFoundPage = false, onClick, className, children }) => {
  return (
    <Container className={className} onClick={onClick} type={type} isNotFoundPage={isNotFoundPage} >
      {children}
      {
        !hiddenIcon &&
        <svg data-name="Forward Arrow" xmlns="http://www.w3.org/2000/svg" width="18.734" height="18.734" viewBox="0 0 18.734 18.734"><rect id="Bounds" width="18.734" height="18.734" fill="none" /><path id="Icon" d="M10.367,4,9.253,5.114,13.71,9.571H4v1.592h9.71L9.253,15.62l1.114,1.114,6.367-6.367Z" transform="translate(-1.002 -1.002)" fill="#fff" /></svg>
      }
    </Container>
  );
};

export default Button;
