import { css, createGlobalStyle } from 'styled-components';
import { mediaQuery } from './mixins';

export const reset = css`
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: none;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    height: 100%;
    margin: 0px;

    ${mediaQuery.phone`
      overflow-x:  hidden;
    `}
  }

  html {
    overflow-x:  hidden;
  }

  body,
  input,
  button {
    font: 14px 'Poppins', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  figure {
    margin: 0;
  }

  img {
    vertical-align: middle;
  }
`;
export const Reset = createGlobalStyle`${reset}`;
export default reset;
